
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      
      OfferDetails: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchOfferDetails: null,
      OfferDetailsSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addOfferDetails() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addOfferDetails",
        inputs: [
        {"model":"offer_id",
"type":"text",
"label":"offer_id"},
{"model":"coupon_id",
"type":"text",
"label":"coupon_id"},
{"model":"x_product_id",
"type":"text",
"label":"x_product_id"},
{"model":"y_product_id",
"type":"text",
"label":"y_product_id"},
{"model":"payment_method_id",
"type":"text",
"label":"payment_method_id"},
{"model":"min_pruches_price",
"type":"text",
"label":"min_pruches_price"},
{"model":"min_product_qty",
"type":"text",
"label":"min_product_qty"},

        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("offer-details", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editOfferDetails(app) {
      var data = {
        title: "popups.offer_details",
        inputs: [
         {"model":"offer_id",
"type":"text",
"label":"offer_id",
"value":app.offer_id
},
    {"model":"coupon_id",
"type":"text",
"label":"coupon_id",
"value":app.coupon_id
},
    {"model":"x_product_id",
"type":"text",
"label":"x_product_id",
"value":app.x_product_id
},
    {"model":"y_product_id",
"type":"text",
"label":"y_product_id",
"value":app.y_product_id
},
    {"model":"payment_method_id",
"type":"text",
"label":"payment_method_id",
"value":app.payment_method_id
},
    {"model":"min_pruches_price",
"type":"text",
"label":"min_pruches_price",
"value":app.min_pruches_price
},
    {"model":"min_product_qty",
"type":"text",
"label":"min_product_qty",
"value":app.min_product_qty
},
    
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("offer-details", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateOfferDetails(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
        {"model":"offer_id",
"type":"text",
"label":"offer_id"},
{"model":"coupon_id",
"type":"text",
"label":"coupon_id"},
{"model":"x_product_id",
"type":"text",
"label":"x_product_id"},
{"model":"y_product_id",
"type":"text",
"label":"y_product_id"},
{"model":"payment_method_id",
"type":"text",
"label":"payment_method_id"},
{"model":"min_pruches_price",
"type":"text",
"label":"min_pruches_price"},
{"model":"min_product_qty",
"type":"text",
"label":"min_product_qty"},

        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("offer-details", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.OfferDetailsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("offer-details/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.OfferDetails = res.data;
          console.log("######## files", this.OfferDetails);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.OfferDetailsSearchMode = false;
      this.get(this.page);
    },
   
    getOfferDetails() {
      this.http.get("offer-details").then((res) => {
        this.OfferDetails = res.data;
      });
    },
    deleteOfferDetails(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("offer-details", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("offer-details/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.OfferDetails = res.data;
          
        });
    },
  },
  created() {
    this.get(1);
    this.getOfferDetails();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.offer_details.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="OfferDetailsSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button
          type="button"
          @click="addOfferDetails()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"  class="table mt-1">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
        <th scope='col'>{{$t('offer_details.id')}}
</th><th scope='col'>{{$t('offer_details.offer_id')}}
</th><th scope='col'>{{$t('offer_details.coupon_id')}}
</th><th scope='col'>{{$t('offer_details.x_product_id')}}
</th><th scope='col'>{{$t('offer_details.y_product_id')}}
</th><th scope='col'>{{$t('offer_details.payment_method_id')}}
</th><th scope='col'>{{$t('offer_details.min_pruches_price')}}
</th><th scope='col'>{{$t('offer_details.min_product_qty')}}
</th><th scope='col'>{{$t('offer_details.created')}}
</th><th scope='col'>{{$t('offer_details.updated')}}
</th> 
        <th scope='col'>{{$t('offer_details.operations')}}
</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app) in OfferDetails" :key="app" class="text-center">
          <td>{{app.offer_id}}</td><td>{{app.coupon_id}}</td><td>{{app.x_product_id}}</td><td>{{app.y_product_id}}</td><td>{{app.payment_method_id}}</td><td>{{app.min_pruches_price}}</td><td>{{app.min_product_qty}}</td><td>{{app.created.split('T')[0]}}</td><td>{{app.updated.split('T')[0]}}</td>
          
          <td class="d-flex">
            <a
              class="btn btn-primary mx-1"
              @click="editOfferDetails(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteOfferDetails(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




